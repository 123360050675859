<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Edit User</strong> </CCardHeader>
          <CCardBody v-if="isLoading" class="text-center">
            <CSpinner color="primary" size="lg" />
          </CCardBody>
          <CForm autocomplete="off" v-else>
            <CCardBody v-if="user_to_edit">
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="First Name"
                    v-model="user_to_edit.first_name"
                    placeholder="Enter first name..."
                    required
                    @keyup="check_first_name"
                    :addInputClasses="first_name_invalid ? 'is-invalid' : null"
                    :isValid="user_to_edit.first_name ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Last Name"
                    v-model="user_to_edit.last_name"
                    placeholder="Enter last name..."
                    required
                    @keyup="check_last_name"
                    :addInputClasses="last_name_invalid ? 'is-invalid' : null"
                    :isValid="user_to_edit.last_name ? true : null"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="NID Number"
                    v-model="user_to_edit.nid_number"
                    placeholder="Enter NID..."
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Bank Account"
                    v-model="user_to_edit.bank_account"
                    placeholder="Enter bank account..."
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Address"
                    v-model="user_to_edit.address"
                    placeholder="Enter Address..."
                  />
                </CCol>
                <CCol sm="6">
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Role</label
                      >
                    </div>
                    <select
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="user_to_edit.role_id"
                    >
                      <template v-for="role in roles">
                        <option
                          :key="role.id"
                          :value="role.id"
                          v-if="role.id !== 11"
                          :selected="role.id == user_to_edit.role_id"
                        >
                          {{ role.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="email"
                    autocomplete="off"
                    label="Email"
                    v-model="user_to_edit.email"
                    placeholder="Enter Email..."
                    required
                    @keyup="check_email"
                    :addInputClasses="email_invalid ? 'is-invalid' : null"
                    :isValid="user_to_edit.email ? true : null"
                  />
                </CCol>
                   <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Contact No"
                    v-model="user_to_edit.contact_no"
                    placeholder="Enter contact number..."
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-between">
                <CButton
                  size="sm"
                  color="success"
                  @click="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Update</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "UserEdit",

  data() {
    return {
      user_to_edit: [],
      email_invalid: false,
      last_name_invalid: false,
      first_name_invalid: false,
      role_id_invalid: false,
      contact_no_invalid: false,
    };
  },
  computed: {
    ...mapGetters({ editUser: "users/editUser" }),
    ...mapState({
      editUser: (state) => state.users.editUser,
      roles: (state) => state.roles.roles,
      isLoading: (state) => state.permissions.isLoading,
      isSaving: (state) => state.users.isSaving,
    }),
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    if (!this.editUser) {
      this.$router.push({ name: "Users" });
    }
    this.$store.dispatch("roles/getRoles");
    this.beforeEditingCache = JSON.stringify(this.editUser);
    this.user_to_edit = this.editUser
      ? JSON.parse(this.beforeEditingCache)[0]
      : [];
  },

  methods: {
    check_last_name() {
      if (!this.user_to_edit.last_name) {
        this.last_name_invalid = true;
      } else {
        this.last_name_invalid = false;
      }
    },
    check_first_name() {
      if (!this.user_to_edit.first_name) {
        this.first_name_invalid = true;
      } else {
        this.first_name_invalid = false;
      }
    },
    check_contact_no() {
      if (!this.contact_no) {
        this.contact_no_invalid = true;
      } else {
        this.contact_no_invalid = false;
      }
    },
    check_email() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.user_to_edit.email).toLowerCase())) {
        this.email_invalid = false;
      } else {
        this.email_invalid = true;
      }
    },
    checkForm() {
      if (
        !this.user_to_edit.email ||
        !this.user_to_edit.last_name ||
        !this.user_to_edit.first_name ||
        !this.user_to_edit.contact_no ||
        !this.user_to_edit.role_id
      ) {
        this.check_last_name();
        this.check_first_name();
        this.check_contact_no();
        this.check_email();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        this.$store
          .dispatch("users/updateUser", {
            Authorization: "bearer " + this.$store.state.access_token,
            email: this.user_to_edit.email,
            address: this.user_to_edit.address,
            id: this.user_to_edit.id,
            nid_number: this.user_to_edit.nid_number,
            bank_account: this.user_to_edit.bank_account,
            last_name: this.user_to_edit.last_name,
            first_name: this.user_to_edit.first_name,
            role_id: this.user_to_edit.role_id,
          })
          .then((response) => {});
      } else {
        this.checkForm();
      }
    },
  },
};
</script>
 